<template>
  <div class="card-birthday-bonus">
    <div v-if="cardImage" class="card-birthday-bonus__image">
      <atomic-picture :src="cardImage" notLazy />
    </div>

    <div class="card-birthday-bonus__container">
      <div class="card-birthday-bonus__content">
        <div
          class="card-birthday-bonus__title"
          v-html="DOMPurify.sanitize(marked.parseInline(cardTitle) as string, { FORBID_TAGS: ['style'] })"
        />

        <div class="card-birthday-bonus__amount">
          <span class="card-birthday-bonus__amount-label">
            {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.levelBonusLabel') }}
          </span>

          <span class="card-birthday-bonus__amount-value">{{ currentBonusValue }}</span>
        </div>
      </div>

      <div class="card-birthday-bonus__actions">
        <div v-if="availableDate" class="card-birthday-bonus__available">
          {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel') }}

          <bonuses-timer :key="availableDate" :expiredAt="availableDate" size="sm" @timeUp="setAvailableDate" />
        </div>

        <button-base
          v-if="!props.hideButton"
          class="card-birthday-bonus__actions-info"
          type="secondary-2"
          size="sm"
          @click="openModal('loyalty-birthday-details')"
        >
          <atomic-icon id="info" />
        </button-base>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';

  const props = defineProps<{
    hideButton?: boolean;
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const loyaltyStore = useLoyaltyStore();
  const { loyaltyAccount, currentLevelName } = storeToRefs(loyaltyStore);
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const { getContent, levelNameWithoutNumber } = useProjectMethods();
  const cardImage = getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.birthdayImage');
  const birthdayBonusesContent: { bonusValue: string; loyaltyLevels: string[] }[] =
    getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.birthdayBonusList') || [];

  const titleContent = getContent(
    globalComponentsContent,
    defaultLocaleGlobalComponentsContent,
    'loyalty.birthdayBonusTitle'
  );

  const cardTitle = computed(() => {
    if (!titleContent) return '';
    const cardLevelName = levelNameWithoutNumber(currentLevelName.value);
    return titleContent.replace('{level}', `<span>${cardLevelName}</span>`);
  });

  const currentBonusValue = computed(() => {
    if (!birthdayBonusesContent.length || !loyaltyAccount.value?.currentLevel?.order) return;
    const currentLevelBirthdayBonusContent = birthdayBonusesContent.find(bonusContent => {
      return bonusContent.loyaltyLevels.includes(String(loyaltyAccount.value?.currentLevel?.order));
    });
    return currentLevelBirthdayBonusContent?.bonusValue ?? '';
  });

  const { openModal } = useModalStore();

  const availableDate = ref<string | undefined>();
  const dayjs = useDayjs();
  const setAvailableDate = (): void => {
    if (!profile.value?.birthdate) return;

    const birthday = dayjs(profile.value.birthdate).set('year', dayjs().year());
    if (dayjs().isBefore(birthday, 'second')) availableDate.value = birthday.format('YYYY-MM-DD');
    else availableDate.value = birthday.add(1, 'year').format('YYYY-MM-DD');
  };

  onMounted(setAvailableDate);
</script>

<style src="~/assets/styles/components/card/birthday-bonus.scss" lang="scss" />
